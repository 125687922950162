<script lang="ts">
  import classnames from 'classnames';

  export let requiredFields = false;
  export let visibleCard = true;
</script>

<section class={classnames('form', { 'form-card': visibleCard }, $$props.class)}>
  <slot />
  {#if $$slots['consent-block']}
    <div class="col-span-full space-y-8">
      <slot name="consent-block" />
    </div>
  {/if}
</section>
{#if requiredFields}
  <p class="required-field">* campi obbligatori</p>
{/if}

<style type="text/scss" lang="scss" scoped>
  .form-card {
    @apply bg-white shadow-form;
  }
  .form {
    @apply grid grid-cols-2 md:grid-cols-6;
    @apply gap-6;
    @apply p-4 md:p-6;
    @apply rounded-lg;
  }

  .required-field {
    @apply font-sans font-semibold text-xs uppercase tracking-wider text-black text-opacity-50;
    @apply mt-4;
  }
</style>
